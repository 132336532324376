import { autoserializeAs } from 'dcerialize';

export class EbookAvailability {
  /**
   * Ebook available
   */
  @autoserializeAs(() => Boolean) available: boolean;

  constructor(available: boolean) {
    this.available = available;
  }
}

import { autoserializeAs, autoserializeAsArray, deserializeAs, IJsonObject } from 'dcerialize';

import { BillingType } from '../utils/enums';
import { UserAddress, UserAddressList } from './user-address';

/**
 * Class to create a user that signed in via social login
 */
export class RegisterOAuth {
  /**
   * token
   */
  @autoserializeAs(() => String) token: string;

  /**
   * client id
   */
  @autoserializeAs(() => String, 'client_id') clientId: string;

  /**
   * code
   */
  @autoserializeAs(() => String) code: string;

  /**
   * redirectUri
   */
  @autoserializeAs(() => String, 'redirect_uri') redirectUri: string;

  /**
   * Language of the user
   */
  @autoserializeAs(() => String) language: string;

  /**
   * Platform type (always 'web')
   */
  @autoserializeAs(() => String) platform: string = 'web';

  /**
   * Constructor
   * @param token
   * @param code
   * @param clientId
   * @param language
   * @param redirectUri
   */

  constructor(token: string, code: string, clientId: string, language: string, redirectUri = '') {
    this.token = token;
    this.code = code;
    this.clientId = clientId;
    this.language = language;
    this.redirectUri = redirectUri;
    this.platform = 'web';
  }
}

/**
 * User basic data class
 */
export class UserBasicData {
  /**
   * Full name
   */
  @autoserializeAs(() => String) name: string;

  /**
   * Last Name
   */
  @autoserializeAs(() => String, 'last_name') lastName: string;

  /**
   * User email
   */
  @autoserializeAs(() => String) email: string;

  constructor(name = '', lastName = '', email = '') {
    this.name = name;
    this.email = email;
    this.lastName = lastName;
  }
}

/**
 * User CRUD class
 */
export class User {
  /**
   * ID
   */
  @autoserializeAs(() => Number) _id!: number;

  /**
   * Username
   */
  @autoserializeAs(() => String) username: string;

  /**
   * Full name
   */
  @autoserializeAs(() => String) name: string;

  /**
   * Last Name
   */
  @autoserializeAs(() => String) lastName: string;

  /**
   * Last Name 2
   */
  @autoserializeAs(() => String) lastName2: string;

  /**
   * Identity document (DNI/Passport ... )
   */
  @autoserializeAs(() => String) identityDocument: string;

  /**
   * User email
   */
  @autoserializeAs(() => String) email: string;

  /**
   * saved courses
   */
  @autoserializeAsArray(() => Number) savedCourses: number[];

  /**
   * saved articles
   */
  @autoserializeAsArray(() => Number) savedArticles?: number[];

  /**
   * Language
   */
  @autoserializeAs(() => String) language: string;

  /**
   * reCaptcha v3 token
   */
  @autoserializeAs(() => String) recaptchaToken?: string;

  /**
   * Faculty id list
   */
  @autoserializeAsArray(() => Number) faculties?: number[];

  /**
   * School id list
   */
  @autoserializeAsArray(() => Number) schools?: number[];

  /**
   * User's telephone
   */
  @autoserializeAs(() => String) telephone?: string;

  /**
   * User's gender
   */
  @autoserializeAs(() => String) gender?: string;

  /**
   * User's birthdate
   */
  @deserializeAs(() => Date) birthdate?: Date;

  /**
   * User's address
   */
  @autoserializeAs(() => UserAddressList) addresses?: UserAddressList;

  /**
   * User's birthdate
   */
  @autoserializeAs(() => Date) dateEndWelcome?: Date;

  /**
   * User actived course ID
   */
  @autoserializeAs(() => String) activeCourse: string;

  /**
   * User img
   */
  @autoserializeAs(() => String) avatar: string;

  /**
   * Date of user creation
   */
  @autoserializeAs(() => Date) createdAt?: Date;

  /**
   * Number of times that the user has been logged in
   */
  @autoserializeAs(() => Number, 'login_count') loginCount?: number;
  /**
   * Store location
   */
  @autoserializeAs(() => String) storeLocation: string | null;
  /**
   * User's documentation
   */
  @autoserializeAs(() => UserDocumentation) documentation?: UserDocumentation;

  /**
   * User's academic background
   */
  @autoserializeAs(() => Number) academicBackground: number;

  /**
   * User's working status
   */
  @autoserializeAsArray(() => Number) workingStatus: number[];

  /**
   * User's subscription
   */
  @autoserializeAs(() => SubscriptionData) subscription?: SubscriptionData;

  /**
   * User's preferred contact method
   */
  @autoserializeAs(() => Number) contactMethod: number;

  /**
   * User's preferred contact schedule
   */
  @autoserializeAs(() => Number) preferredSchedule: number;

  /**
   * User secondary emails
   */
  @autoserializeAsArray(() => SecondaryEmail) secondaryEmails: SecondaryEmail[];

  constructor(
    name = '',
    lastName = '',
    lastName2 = '',
    identityDocument = '',
    username = '',
    email = '',
    language = '',
    recaptchaToken = '',
    activeCourse = '',
    avatar = '',
    storeLocation = null,
    academicBackground = -1,
    workingStatus = [],
    savedCourses = [],
    contactMethod = -1,
    preferredSchedule = -1,
    secondaryEmails = []
  ) {
    this.name = name;
    this.username = username;
    this.email = email;
    this.lastName = lastName;
    this.lastName2 = lastName2;
    this.identityDocument = identityDocument;
    this.language = language;
    this.recaptchaToken = recaptchaToken;
    this.lastName = lastName;
    this.lastName2 = lastName2;
    this.identityDocument = identityDocument;
    this.activeCourse = activeCourse;
    this.avatar = avatar;
    this.storeLocation = storeLocation;
    this.academicBackground = academicBackground;
    this.workingStatus = workingStatus;
    this.savedCourses = savedCourses;
    this.contactMethod = contactMethod;
    this.preferredSchedule = preferredSchedule;
    this.secondaryEmails = secondaryEmails;
  }

  public hasRequiredData(): boolean {
    return Boolean(this.hasEnrolRequiredData() && this.lastName2);
  }

  public hasEnrolRequiredData(): boolean {
    return Boolean(this.hasInfoRequiredData() && this.identityDocument);
  }

  public hasInfoRequiredData(): boolean {
    return Boolean(this.email && this.name && this.lastName && this.telephone);
  }

  public hasTicketRequiredData(): boolean {
    return Boolean(this.email && this.name && this.lastName && this.identityDocument);
  }

  public addAddress(address: UserAddress): void {
    if (this.addresses?.items == undefined) {
      this.addresses = new UserAddressList();
    }
    if (address.defaultAddress) {
      for (const userAddress of this.addresses.items) {
        userAddress.defaultAddress = false;
      }
    }

    this.addresses.items.push(address);
    this.addresses.total++;
  }

  public updateAddress(address: UserAddress, index: number): void {
    if (!this.addresses) {
      this.addresses = new UserAddressList();
    }
    if (address.defaultAddress) {
      for (const userAddress of this.addresses.items) {
        userAddress.defaultAddress = false;
      }
    }
    this.addresses.items[index] = address;
  }

  public removeAddress(index: number): void {
    if (!this.addresses) {
      this.addresses = new UserAddressList();
    } else {
      this.addresses.items.splice(index, 1);
      this.addresses.total--;
    }
  }
}

export class UserDocumentation {
  /**
   * Identity document (DNI)
   */
  @autoserializeAs(() => DocumentInformation) identityDocument?: DocumentInformation;

  /**
   * Curriculum Vitae
   */
  @autoserializeAs(() => DocumentInformation) curriculumVitae?: DocumentInformation;

  /**
   * Others
   */
  @autoserializeAs(() => DocumentInformation) others?: DocumentInformation;

  constructor(
    identityDocument?: DocumentInformation,
    curriculumVitae?: DocumentInformation,
    others?: DocumentInformation
  ) {
    this.identityDocument = identityDocument;
    this.curriculumVitae = curriculumVitae;
    this.others = others;
  }
}

export class DocumentInformation {
  /**
   * File name
   */
  @autoserializeAs(() => String) filename: string;

  /**
   * Alfresco id
   */
  @autoserializeAs(() => String) idNode: string;

  /**
   * File size
   */
  @autoserializeAs(() => Number) size: number;

  /**
   * Document name
   */
  @autoserializeAs(() => String) docName: string;

  constructor(filename: string, idNode: string, size: number, docName: string) {
    this.filename = filename;
    this.idNode = idNode;
    this.size = size;
    this.docName = docName;
  }
}

/**
 * User secondary email
 */
export class SecondaryEmail {
  /**
   * Secondary email
   */
  @autoserializeAs(() => String) email: string;

  /**
   * Wheter the email is verified
   */
  @autoserializeAs(() => Boolean) verified: boolean;

  constructor(email: string, verified: boolean) {
    this.email = email;
    this.verified = verified;
  }
}

export class SecondaryEmailList {
  /**
   * Secondary email list
   */
  @autoserializeAsArray(() => SecondaryEmail) items: SecondaryEmail[];

  /**
   * Total number of secondary emails
   */

  @autoserializeAs(() => Number) total: number;

  constructor(items = [], total = 0) {
    this.items = items;
    this.total = total;
  }
}

/**
 * User data to update
 */
export class UserDataUpdate {
  /**
   * Full name
   */
  @autoserializeAs(() => String) name?: string;

  /**
   * Last Name
   */
  @autoserializeAs(() => String) lastName?: string;

  /**
   * Last Name 2
   */
  @autoserializeAs(() => String) lastName2?: string;

  /**
   * Identity document (DNI/Passport ... )
   */
  @autoserializeAs(() => String) identityDocument?: string;

  /**
   * Username
   */
  @autoserializeAs(() => String) username?: string;

  /**
   * Language
   */
  @autoserializeAs(() => String) language?: string;

  /**
   * Faculty id list
   */
  @autoserializeAsArray(() => Number) faculties?: number[];

  /**
   * School id list
   */
  @autoserializeAsArray(() => Number) schools?: number[];

  /**
   * User's telephone
   */
  @autoserializeAs(() => String) telephone?: string;

  /**
   * User's gender
   */
  @autoserializeAs(() => String) gender?: string;

  /**
   * User's birthdate
   */
  @deserializeAs(() => Date) birthdate?: Date;

  /**
   * User's address
   */
  @autoserializeAs(() => UserAddressList) addresses?: UserAddressList;

  /**
   * User active course
   */
  @autoserializeAs(() => String) activeCourse?: string;

  /**
   * User's academic background
   */
  @autoserializeAs(() => Number) academicBackground?: number;

  /**
   * User's working status
   */
  @autoserializeAsArray(() => String) workingStatus?: string[];

  /**
   * User's preferred contact method
   */
  @autoserializeAs(() => Number) contactMethod?: number;

  /**
   * User's preferred contact schedule
   */
  @autoserializeAs(() => Number) preferredSchedule?: number;

  /**
   * User secondary emails
   */
  @autoserializeAsArray(() => SecondaryEmail) secondaryEmails?: SecondaryEmail[];

  constructor(
    name?: string,
    username?: string,
    language?: string,
    faculties?: number[],
    schools?: number[],
    telephone?: string,
    gender?: string,
    birthdate?: Date,
    addresses?: UserAddressList,
    activeCourse?: string,
    lastName?: string,
    lastName2?: string,
    identityDocument?: string,
    academicBackground?: number,
    workingStatus?: string[],
    contactMethod?: number,
    preferredSchedule?: number,
    secondaryEmails?: SecondaryEmail[]
  ) {
    this.name = name;
    this.username = username;
    this.language = language;
    this.faculties = faculties;
    this.schools = schools;
    this.telephone = telephone;
    this.gender = gender;
    this.birthdate = birthdate;
    this.addresses = addresses;
    this.activeCourse = activeCourse;
    this.lastName = lastName;
    this.lastName2 = lastName2;
    this.identityDocument = identityDocument;
    this.academicBackground = academicBackground;
    this.workingStatus = workingStatus;
    this.contactMethod = contactMethod;
    this.preferredSchedule = preferredSchedule;
    this.secondaryEmails = secondaryEmails;
  }

  public addAddress(address: UserAddress): void {
    if (this.addresses?.items == undefined) {
      this.addresses = new UserAddressList();
    }
    if (address.defaultAddress) {
      for (const userAddress of this.addresses.items) {
        userAddress.defaultAddress = false;
      }
    }

    this.addresses.items.push(address);
    this.addresses.total++;
  }

  public updateAddress(address: UserAddress, index: number): void {
    if (!this.addresses) {
      this.addresses = new UserAddressList();
    }
    if (address.defaultAddress) {
      for (const userAddress of this.addresses.items) {
        userAddress.defaultAddress = false;
      }
    }
    this.addresses.items[index] = address;
  }

  public removeAddress(index: number): void {
    if (!this.addresses) {
      this.addresses = new UserAddressList();
    } else {
      this.addresses.items.splice(index, 1);
      this.addresses.total--;
    }
  }

  static onSerialized(json: IJsonObject, instance: UserDataUpdate): void {
    json['birthdate'] = instance.birthdate?.toISOString();
  }
}

export class SubscriptionData {
  @deserializeAs(() => String) transactionID: string;
  @deserializeAs(() => String) product: string;
  @deserializeAs(() => Date) startAt: Date;
  @deserializeAs(() => Date) finishAt: Date;

  constructor(transactionID: string, product: string, startAt: Date, finishAt: Date) {
    this.transactionID = transactionID;
    this.product = product;
    this.startAt = startAt;
    this.finishAt = finishAt;
  }

  isActive(): boolean {
    return this.finishAt.getTime() > Date.now();
  }

  /**
   * Returns the billing type that corresponds to the format of the user subscription.
   * @return The billing type determined from the user subscription
   */
  getBillingType(): BillingType {
    if (
      (this.product.includes('_courses_subscription') || this.product.includes('GPA')) &&
      this.finishAt >= new Date()
    ) {
      return BillingType.Innopay;
    } else {
      return BillingType.Stripe;
    }
  }
}

export class InnotutorData {
  /**
   * Data id
   */
  @autoserializeAs(() => Number) id: number;

  /**
   * Data name
   */
  @autoserializeAs(() => String) name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }
}

export class InnotutorDataList {
  /**
   * InnotutorData items
   */
  @autoserializeAsArray(() => InnotutorData) items: InnotutorData[];

  /**
   * InnotutorData total
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: InnotutorData[], total: number) {
    this.items = items;
    this.total = total;
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Deserialize, IJsonObject, Serialize } from 'dcerialize';
import { firstValueFrom, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { Article, ArticleList } from 'src/models/article';

import { RestorePasswordInterface } from '../definitions/security.interface';
import { environment } from '../environments/environment';
import { Certificate, CertificateList } from '../models/certificate';
import { Course, CourseFilter, CourseParametersFilter, CourseReduced, CourseReducedList } from '../models/course';
import { DocumentUrl, UrlRedirect } from '../models/document.url';
import { EbookAvailability } from '../models/e-book';
import { EnrolmentPaymentList } from '../models/enrolment-payment';
import {
  EnrolmentProgress,
  EnrolmentProgressList,
  EnrolmentWithCourse,
  EnrolmentWithCourseList
} from '../models/enrolment-with-course';
import { LoginResponse } from '../models/loginResponse';
import { SavedCourses } from '../models/saved-courses';
import { TicketList, TicketParametersFilter } from '../models/ticket';
import { InnotutorDataList, SecondaryEmailList, SubscriptionData, User, UserDataUpdate } from '../models/user';
import { Webinar } from '../models/webinar';
import { WebinarEnrolment, WebinarEnrolmentList } from '../models/webinar_enrolment';
import { CheckCertificatesTypes, DocumentationType, EnrolmentStatus } from '../utils/enums';
import { setStorageObject } from '../utils/storage-manager';
import { ApiService } from './api.service';
import { CustomCookieService } from './custom-cookie.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  protected http = inject(HttpClient);
  private apiService = inject(ApiService);
  private customCookieService = inject(CustomCookieService);

  /**
   * API path
   */
  path = 'user';

  /**
   * API path v2
   */
  pathV2 = 'user';

  eBookReady: Subject<boolean> = new ReplaySubject();

  syncEnrollments: Subject<boolean> = new ReplaySubject();

  coursesProgress: Subject<EnrolmentProgress[]> = new ReplaySubject();

  /**
   * Subject to emit the current language of the user
   */
  userCurrentLanguage = new Subject<string>();

  /**
   * Observable to get the current language of the user
   */
  userCurrentLanguage$ = this.userCurrentLanguage.asObservable();

  /**
   * Subject to emit the loading for updating language flag
   */
  loadingForUpdateLanguage = new Subject<boolean>();

  /**
   * Observable to set loading for updating language
   */
  loadingForUpdateLanguage$ = this.loadingForUpdateLanguage.asObservable();

  /**
   * Subject to emit the current user
   */
  currentUserUpdated = new Subject<User>();

  /**
   * Observable to get the current user
   */
  currentUserUpdated$ = this.currentUserUpdated.asObservable();

  constructor() {
    this.path = this.apiService.getApiUrl() + '/' + this.path;
    this.pathV2 = this.apiService.getApiUrl() + '/v2/' + this.pathV2;
  }

  /**
   * Method to refresh the access token with the refresh token
   *
   * @returns The new access token
   */
  refreshAuthToken(): Observable<LoginResponse> {
    return this.http.post<IJsonObject>(`${this.path}/refresh-token`, {}).pipe(
      map((response) => {
        const token = Deserialize(response, () => LoginResponse);
        setStorageObject(environment.accessTokenStorage, token.accessToken);
        if (token.accessToken) {
          this.customCookieService.createUserSessionCookie(token.accessToken, this.apiService.getSubdomain());
        }

        return token;
      })
    );
  }

  /**
   * Method to register a user in a course
   */
  registerUserInCourse(courseId: number): Observable<UrlRedirect> {
    return this.http
      .post<IJsonObject>(`${this.path}/course/${courseId}`, { domain: new URL(window.origin).origin })
      .pipe(map((paymentUrl) => Deserialize(paymentUrl, () => UrlRedirect)));
  }

  /**
   * Method to get the courses where the current user is registered
   *
   * @return EnrolmentWithCourseList
   */
  getCoursesRegistered(
    params: CourseParametersFilter = new CourseParametersFilter(),
    updateEnrolmentStorage = false,
    hideEnrolmentWithoutAccess = false
  ): Observable<EnrolmentWithCourseList> {
    params.sort = params.sort ? params.sort : '-lastAccess';

    const extendedParams = {
      ...params,
      hideEnrolmentsWithoutAccess: true
    };

    return this.http
      .post<IJsonObject>(`${this.pathV2}/courses`, hideEnrolmentWithoutAccess ? extendedParams : params)
      .pipe(
        mergeMap((enrolmentWithCourseList) => {
          const result = Deserialize(enrolmentWithCourseList, () => EnrolmentWithCourseList);
          if (updateEnrolmentStorage) {
            const userEnrolments = result.items.map((enrolment) => {
              return {
                id: enrolment.courseId,
                status:
                  enrolment.status === EnrolmentStatus.Processing ? EnrolmentStatus.Processing : EnrolmentStatus.Active,
                idSimo: enrolment.course.idSimo,
                cif: enrolment.cif,
                code: enrolment.course.code,
                name: enrolment.course.name
              };
            });
            setStorageObject(environment.userEnrolmentStorage, userEnrolments);

            return of(result);
          } else {
            return of(result);
          }
        })
      );
  }

  /**
   * Method to get the progress of the courses where the current user is registered
   * @return EnrolmentWithCourseList
   */
  getProgressCoursesRegistered(): Observable<EnrolmentProgressList> {
    return this.http.get<IJsonObject>(`${this.path}/courses-progress?sort=-lastAccess&limit=-1`).pipe(
      map((newProgressEnrolments) => {
        const returnValue = Deserialize(newProgressEnrolments, () => EnrolmentProgressList);
        this.courseProgressLoaded(returnValue.items);

        return returnValue;
      })
    );
  }

  /**
   * Method to sync innotutor enrolments
   *
   */
  syncEnrolments(hardReload = false): Observable<any> {
    return this.http.post(`${this.path}/sync-innotutor-enrolments`, { hardReload }).pipe(
      map(() => {
        this.syncEnrolmentsDone();
        this.checkEBookForSync();
      })
    );
  }

  /**
   * Method to save a course in user bookmarks
   */
  saveCourse(course: Course | CourseReduced): Observable<number[]> {
    return this.http.post<IJsonObject>(`${this.path}/course/${course._id}/save`, null).pipe(
      map((user) => {
        const result = Deserialize(user, () => User);
        setStorageObject(environment.userCourseBookmarksStorage, result.savedCourses);

        return result.savedCourses;
      })
    );
  }

  /**
   * Method to save a article in user bookmarks
   */
  saveArticle(article: Article): Observable<number[] | undefined> {
    return this.http.post<IJsonObject>(`${this.path}/article/${article._id}/save`, null).pipe(
      map((user) => {
        const result = Deserialize(user, () => User);
        setStorageObject(environment.userArticleBookmarksStorage, result.savedArticles);

        return result.savedArticles;
      })
    );
  }

  /**
   * Method to get user saved courses
   */
  getSavedCoursesIds(updateCourseBookmarksStorage = false): Observable<SavedCourses> {
    return this.http.get<IJsonObject>(`${this.path}/saved-courses-ids`).pipe(
      map((courseList) => {
        const result = Deserialize(courseList, () => SavedCourses);

        if (updateCourseBookmarksStorage) {
          setStorageObject(environment.userCourseBookmarksStorage, result.savedCourses);
        }

        return result;
      })
    );
  }

  /**
   * Get the current user
   *
   * @returns element retrieved user-profile from the CRUD service
   */
  profile(): Observable<User> {
    return this.http.get<IJsonObject>(this.path + '/profile').pipe(map((user) => Deserialize(user, () => User)));
  }

  /**
   * CRUD: UPDATE method
   *
   * @param user - user to update
   */
  updateCurrentUser(user: UserDataUpdate): Observable<User> {
    return this.http
      .patch<IJsonObject>(
        `${this.path}/profile`,
        Serialize(user, () => UserDataUpdate)
      )
      .pipe(
        map((newUser) => {
          const user = Deserialize(newUser, () => User);
          this.updateUserStorage(user);

          return user;
        })
      );
  }

  /**
   * Method to get the current user saved courses
   * @return EnrolmentWithCourseList
   */
  getSavedCourses(apmParams: CourseParametersFilter): Observable<CourseReducedList> {
    const params = new HttpParams({
      fromObject: { ...apmParams, filter: JSON.stringify(apmParams.filter) }
    });

    return this.http
      .get<IJsonObject>(`${this.path}/saved-courses`, { params })
      .pipe(map((savedCourses) => Deserialize(savedCourses, () => CourseReducedList)));
  }

  /**
   * Method to get the current user saved articles
   * @return ArticleList
   */
  getSavedArticles(): Observable<ArticleList> {
    return this.http.get<IJsonObject>(`${this.path}/saved-articles`).pipe(
      map((savedArticles) => {
        const articles = Deserialize(savedArticles, () => ArticleList);
        const data = articles?.items.map((article) => article._id);
        setStorageObject(environment.userArticleBookmarksStorage, data);

        return articles;
      })
    );
  }

  /**
   * Emit when the enrolments was sync
   */
  syncEnrolmentsDone(): void {
    this.syncEnrollments.next(true);
  }

  /**
   * Emit when the eBook can be checked
   */
  checkEBookForSync(): void {
    this.eBookReady.next(true);
  }

  /**
   * Progress loaded
   */
  courseProgressLoaded(enrolmentProgress: EnrolmentProgress[]): void {
    this.coursesProgress.next(enrolmentProgress);
  }

  /**
   * Method to sync innotutor tickets
   */
  syncTickets(): Observable<any> {
    return this.http.post(`${this.path}/sync-innotutor-tickets`, {});
  }

  resetReplaySubjects(): void {
    this.syncEnrollments.complete();
    this.syncEnrollments = new ReplaySubject();
    this.coursesProgress.complete();
    this.coursesProgress = new ReplaySubject();
  }

  /**
   * Method to get the all payments from courses where the current user is registered
   * @return EnrolmentWithCourseList
   */
  listCoursesPayments(): Observable<EnrolmentPaymentList> {
    return this.http.get<IJsonObject>(`${this.pathV2}/courses-payments`).pipe(
      map((enrolmentPaymentList) => {
        return Deserialize(enrolmentPaymentList, () => EnrolmentPaymentList);
      })
    );
  }

  /**
   * Method to get course enrolment bill
   */
  getEnrolmentBill(billId: number): Observable<DocumentUrl> {
    return this.http
      .post<IJsonObject>(`${this.path}/courses-bill/${billId}`, null)
      .pipe(map((billUrl) => Deserialize(billUrl, () => DocumentUrl)));
  }

  async getUserEnrolmentFromCourse(course: Course | CourseReduced): Promise<EnrolmentWithCourse | undefined> {
    const userEnrolments = await firstValueFrom(
      this.getCoursesRegistered(new CourseParametersFilter(new CourseFilter(), '', -1))
    );
    const enrolment = userEnrolments.items.find((enrol) => enrol.courseId === course._id);

    return enrolment;
  }
  /**
   * Register current user in a course with enrolment status as processing
   *
   * @returns courseId course id
   */
  registerInCourseAsInProgress(courseId: number): Observable<any> {
    return this.http.post<IJsonObject>(`${this.path}/course/${courseId}/processing`, null);
  }
  /**
   * Method to upload a document
   * @param file - File to upload
   * @param type - The type of the file
   * @param docName - Name of the file, used when the type is other
   */
  uploadDocument(file: File, type: DocumentationType, docName = ''): Observable<User> {
    const formData: FormData = new FormData();
    formData.append('file', file);
    let url = `${this.path}/upload-document/` + type;
    if (type === DocumentationType.Others) {
      url = url + '/' + docName;
    }

    return this.http.post<IJsonObject>(url, formData).pipe(
      map((newUser) => {
        return Deserialize(newUser, () => User);
      })
    );
  }

  downloadDocument(type: DocumentationType): Observable<any> {
    return this.http.get<string>(this.path + '/get-document/' + type);
  }

  /**
   * Method to delete a document
   * @param docType The document type
   */
  deleteDocument(docType: DocumentationType): Observable<any> {
    return this.http.delete<IJsonObject>(`${this.path}/delete-document/` + docType);
  }

  /**
   * Method to get user tickets
   *
   * @returns ticket list
   */
  getTickets(ticketParametersFilter: TicketParametersFilter): Observable<TicketList> {
    return this.http
      .post<IJsonObject>(`${this.path}/ticket`, ticketParametersFilter)
      .pipe(map((ticketList) => Deserialize(ticketList, () => TicketList)));
  }

  /**
   * Get the course certificate or degree
   * @param enrolmentId Identifies the enrolment that corresponds to the wanted certificate/degree
   */
  getCertificate(enrolmentId: string): Observable<string> {
    return this.http.get<string>(`${this.path}/enrolment/${enrolmentId}/certificate`);
  }
  /**
   * Method to get certificates from courses where the current user is registered
   */
  listCoursesCertificates(params?: CourseParametersFilter): Observable<CertificateList> {
    return this.http.post<IJsonObject>(`${this.pathV2}/courses-certificates`, params).pipe(
      map((certificateList) => {
        return Deserialize(certificateList, () => CertificateList);
      })
    );
  }

  getCourseCertificateById(id: number): Observable<Certificate> {
    const params = new HttpParams({
      fromObject: {
        filter: `{"_id": ${id}}`,
        sort: '',
        limit: -1,
        page: 0
      }
    });

    return this.http.get<IJsonObject>(`${this.path}/course-certificate`, { params }).pipe(
      map((certificateList) => {
        return Deserialize(certificateList, () => CertificateList).items[0];
      })
    );
  }

  getUserSubscription(): Observable<SubscriptionData | undefined> {
    return this.http.get<IJsonObject>(`${this.path}/subscription`).pipe(
      map((subscriptionResponse: any) => {
        const data = subscriptionResponse.subscription;
        let subscription;
        if (Object.keys(data).length) {
          subscription = Deserialize(data, () => SubscriptionData) as unknown as SubscriptionData;
        }

        return subscription;
      })
    );
  }

  getRecommendedCourses(): Observable<CourseReducedList> {
    return this.http
      .get<IJsonObject>(`${this.path}/recommendations`)
      .pipe(map((recommendedCourses) => Deserialize(recommendedCourses, () => CourseReducedList)));
  }

  /**
   * Method to get children courses of a root course where the current user is registered
   *
   * @return EnrolmentWithCourseList
   */
  getChildrenCoursesRegisteredByParentEnrolmentId(enrolmentId: string): Observable<EnrolmentWithCourseList> {
    return this.http
      .get<IJsonObject>(`${this.pathV2}/enrolment/${enrolmentId}/children`)
      .pipe(map((enrolmentWithCourseList) => Deserialize(enrolmentWithCourseList, () => EnrolmentWithCourseList)));
  }

  saveLocationStore(location: string): Observable<any> {
    return this.http.post(`${this.path}/location-store`, { location });
  }

  updateUserLanguage(languageCode: string): void {
    this.userCurrentLanguage.next(languageCode);
  }

  setLoadingForUpdateLanguage(): void {
    this.loadingForUpdateLanguage.next(true);
  }

  updateUserStorage(user: User, storage = 'local'): void {
    setStorageObject(environment.userDataStorage, user, storage);
    this.currentUserUpdated.next(user);
  }

  /**
   * Method to get the education levels options from Innotutor
   */
  getEducationLevels(): Observable<InnotutorDataList> {
    return this.http
      .get<IJsonObject>(`${this.path}/education-levels`)
      .pipe(map((innotutorDataList) => Deserialize(innotutorDataList, () => InnotutorDataList)));
  }

  /**
   * Method to get the employment statuses options from Innotutor
   */
  getEmploymentStatuses(): Observable<InnotutorDataList> {
    return this.http
      .get<IJsonObject>(`${this.path}/employment-statuses`)
      .pipe(map((innotutorDataList) => Deserialize(innotutorDataList, () => InnotutorDataList)));
  }

  changeCiamPass(currentPass: string, newPass: string): Observable<any> {
    const body = {
      currentPassword: currentPass,
      newPassword: newPass
    };

    return this.http.post(`${this.path}/ciam/change-pass`, body);
  }

  sendRecoveryCode(): Observable<RestorePasswordInterface> {
    return this.http.get<RestorePasswordInterface>(`${this.path}/ciam/restore-password/code`);
  }

  restorePassword(data: RestorePasswordInterface): Observable<void> {
    const body = {
      key: data.key,
      code: data.code,
      password: data.password,
      recoveryKey: data.recoveryKey
    };

    return this.http.post<void>(`${this.path}/ciam/restore-password`, body);
  }

  /**
   * Method to verify the password of the user
   * @param password
   */
  verifyCiamPassword(password: string): Observable<void> {
    return this.http.post<void>(`${this.path}/ciam/verify-password`, { password: password });
  }

  /**
   * Method to send the code to unify accounts
   */
  sendCodeToMergeAccounts(email: string): Observable<void> {
    return this.http.post<void>(`${this.path}/ciam/merge-accounts`, { userEmailToMerge: email });
  }

  /**
   * Method to unify accounts
   */
  mergeAccounts(email: string, code: number): Observable<void> {
    return this.http.put<void>(`${this.path}/ciam/merge-accounts`, { userEmailToMerge: email, code: code });
  }

  /**
   * Send the code to verify the email
   * @param email The email where the code has been sent
   */
  sendCodeToAddEmail(email: string): Observable<void> {
    return this.http.post<void>(`${this.path}/ciam/email/code`, { email: email });
  }

  /**
   * Verify the code to add email and add it if correct
   * @param email The email of the account where the new email is going to be added
   * @param code The code that has to be verified
   */
  verifyCodeToAddEmail(email: string, code: number): Observable<SecondaryEmailList> {
    return this.http
      .put<IJsonObject>(`${this.path}/ciam/email/code/verify`, { email: email, code: code })
      .pipe(map((secondaryEmailList) => Deserialize(secondaryEmailList, () => SecondaryEmailList)));
  }

  /**
   * Method to check if the email exists
   * @param email The email to be checked
   */
  checkSecondaryEmail(email: string): Observable<void> {
    const params = new HttpParams().set('email', email);

    return this.http.get<void>(`${this.path}/secondary-email/check`, { params });
  }

  /**
   * Method to add a new email
   */
  setMainEmail(email: string): Observable<SecondaryEmailList> {
    return this.http.post<SecondaryEmailList>(`${this.path}/ews/set-main-email`, { email: email });
  }

  /**
   * Delete the specified email from user secondary emails
   */
  deleteSecondaryEmail(email: string): Observable<SecondaryEmailList> {
    const params = new HttpParams().set('email', email);

    return this.http
      .delete<IJsonObject>(`${this.path}/ews/secondary-email`, { params })
      .pipe(map((secondaryEmailList) => Deserialize(secondaryEmailList, () => SecondaryEmailList)));
  }

  /**
   * Method to logout user in ciam
   */
  logout(): Observable<void> {
    return this.http.get<void>(`${this.path}/ciam/logout`);
  }

  /**
   * Method to add a secondary email in our DB without verify it on EWS
   */
  addEmailVerifyLater(emailToAdd: string): Observable<SecondaryEmailList> {
    return this.http
      .post<IJsonObject>(`${this.path}/ews/secondary-email`, { email: emailToAdd, verified: false })
      .pipe(map((secondaryEmailList) => Deserialize(secondaryEmailList, () => SecondaryEmailList)));
  }

  /**
   * Method to get the current user enroled webinars
   * @return WebinarEnrolmentList
   */
  getEnroledWebinars(): Observable<WebinarEnrolmentList> {
    return this.http.get<IJsonObject>(`${this.path}/enroled-webinars`).pipe(
      map((webinarEnrolmentData) => {
        const webinarEnrolments = Deserialize(webinarEnrolmentData, () => WebinarEnrolmentList);
        const data = webinarEnrolments?.items.map((webinar) => webinar.webinarId);
        setStorageObject(environment.userWebinarEnrolmentStorage, data);

        return webinarEnrolments;
      })
    );
  }

  /**
   * Method to enrol the current user on a webinar
   * @param webinar The webinar the user will be enroled
   * @return WebinarEnrolment
   */
  enrolUserInWebinar(webinar: Webinar): Observable<WebinarEnrolment> {
    return this.http
      .post<IJsonObject>(`${this.path}/webinar/${webinar._id}/enrol`, null)
      .pipe(map((webinarEnrolment) => Deserialize(webinarEnrolment, () => WebinarEnrolment)));
  }

  /**
   * Method to unenrol the current user from a webinar
   * @param webinar The webinar the user will be unenroled
   */
  unenrolUserInWebinar(webinar: Webinar): Observable<void> {
    return this.http.delete<void>(`${this.path}/webinar/${webinar._id}/unenrol`);
  }

  /**
   * Fetch a list of all available Innotutor certificates depending on the type
   * @param certificateType (0 for any enrolment with certificates, 1 for in progress and 2 for finished)
   */
  checkInnotutorAvailableCertificatesSchema(certificateType = CheckCertificatesTypes.ALL): Observable<CertificateList> {
    return this.http
      .post<IJsonObject>(`${this.path}/innotutor-courses-certificates`, { certificateType: certificateType.toString() })
      .pipe(
        map((certificateList) => {
          return Deserialize(certificateList, () => CertificateList);
        })
      );
  }

  /**
   * Check if the ebook is available in the current user
   */
  checkIfEbookIsAvailable(): Observable<EbookAvailability> {
    return this.http.get<IJsonObject>(`${this.path}/e-book/available`).pipe(
      map((ebookAvailability) => {
        return Deserialize(ebookAvailability, () => EbookAvailability);
      })
    );
  }

  /**
   * Get the ebook url
   */
  getEbookUrl(): Observable<UrlRedirect> {
    return this.http.get<IJsonObject>(`${this.path}/e-book`).pipe(
      map((ebookUrl) => {
        return Deserialize(ebookUrl, () => UrlRedirect);
      })
    );
  }
}

import { autoserializeAs, autoserializeAsArray } from 'dcerialize';

import { Payment } from './payment';
import { RestItemList } from './rest-item';

/* eslint-disable @typescript-eslint/naming-convention */
export const currencyNameMaping: Record<string, string> = {
  EUR: '€',
  USD: '$',
  BOB: 'Bs',
  BRL: 'R$',
  BZD: 'BZ$',
  DOP: 'RD$',
  GBP: '£',
  GTQ: 'Q',
  HNL: 'L',
  MAD: 'د.م.',
  NIO: 'C$',
  PEN: 'S/',
  PHP: '₱',
  PYG: '₲',
  TTD: 'TT$',
  UYU: 'UY$',
  VES: 'Bs',
  XAF: 'FCFA',
  ARS: 'AR$',
  CLP: 'CLP$',
  COP: 'COP$',
  MXN: 'MX$'
};

export class EnrolmentPayment {
  /**
   * Course name
   */
  @autoserializeAs(() => String) courseName: string;

  /**
   * Enrolment ID
   */
  @autoserializeAs(() => String) enrolmentId: string;

  /**
   * Enrolment ID
   */
  @autoserializeAs(() => Number) billId: number;

  /**
   * Total amount
   */
  @autoserializeAs(() => Number) total: number;

  /**
   * Paid amount
   */
  @autoserializeAs(() => Number) paid: number;

  /**
   * Payment currency
   */
  @autoserializeAs(() => String) currency: string;

  /**
   * Whether is tokenized or not
   */
  @autoserializeAs(() => Boolean) tokenized: boolean;

  /**
   * Payments list
   */
  @autoserializeAsArray(() => Payment) installments: Payment[];

  /**
   *
   * @param courseName
   * @param enrolmentId
   * @param billId
   * @param total
   * @param paid
   * @param installments
   * @param currency
   * @param tokenized
   */

  constructor(
    courseName: string,
    enrolmentId: string,
    billId: number,
    total: number,
    paid: number,
    currency: string,
    tokenized: boolean,
    installments: Payment[]
  ) {
    this.courseName = courseName;
    this.enrolmentId = enrolmentId;
    this.billId = billId;
    this.total = total;
    this.paid = paid;
    this.currency = currency;
    this.tokenized = tokenized;
    this.installments = installments;
  }

  public isPaid(): boolean {
    return this.total === this.paid;
  }

  /**
   *  Method to return the currency
   */
  getCurrency(): string {
    return currencyNameMaping[this.currency] || '€';
  }
}

export class EnrolmentPaymentList extends RestItemList<EnrolmentPayment> {
  /**
   * EnrolmentPayment list
   */
  @autoserializeAsArray(() => EnrolmentPayment) items: EnrolmentPayment[];

  /**
   * Number of items
   */
  @autoserializeAs(() => Number) total: number;

  constructor(items: EnrolmentPayment[] = [], total = 0) {
    super();
    this.items = items;
    this.total = total;
  }
}

export class SelectedPayment {
  enrolmentPayment: EnrolmentPayment;
  index: number;

  constructor(enrolmentPayment: EnrolmentPayment, index: number) {
    this.enrolmentPayment = enrolmentPayment;
    this.index = index;
  }

  /**
   * Method to return the enrolmentId
   */
  getEnrolmentId(): string {
    return this.enrolmentPayment.enrolmentId;
  }
}

import { autoserializeAs } from 'dcerialize';

import { PaymentMethod, PaymentStatus } from '../definitions/payment-status.enum';

/**
 * Class that represents an enrolment payment
 */
export class Payment {
  /**
   * Payment id
   */
  @autoserializeAs(() => Number) id: number;

  /**
   * Payment amount
   */
  @autoserializeAs(() => Number) amount: number;

  /**
   * Payment date
   */
  @autoserializeAs(() => Date) paymentDate: Date;

  /**
   * Payment status
   */
  @autoserializeAs(() => String) status: PaymentStatus;

  /**
   * Whether it's paid or not
   */
  @autoserializeAs(() => Boolean) paid: boolean;

  /**
   * Payment method
   */
  @autoserializeAs(() => String) paymentMethod: PaymentMethod;
  /**
   * Payment id
   */
  @autoserializeAs(() => Number) installmentNumber: number;

  /**
   *
   * @param id
   * @param amount
   * @param paymentDate
   * @param status
   * @param paid
   * @param paymentMethod
   * @param installmentNumber
   */

  constructor(
    id: number,
    amount: number,
    paymentDate: Date,
    status: PaymentStatus,
    paid = false,
    paymentMethod: PaymentMethod,
    installmentNumber: number
  ) {
    this.id = id;
    this.amount = amount;
    this.paymentDate = paymentDate;
    this.status = status;
    this.paid = paid;
    this.paymentMethod = paymentMethod;
    this.installmentNumber = installmentNumber;
  }
}

/**
 * Class that represents the price of a subscription
 */
export class SubscriptionPrice {
  /**
   * Subscription price
   */
  @autoserializeAs(() => String) price?: string;

  /**
   * Subscription currency
   */
  @autoserializeAs(() => String) currency?: string;

  /**
   * Subscription interval
   */
  @autoserializeAs(() => String) interval?: string;

  /**
   * Subscription interval count
   */
  @autoserializeAs(() => Number) intervalCount?: number;

  /**
   * Subscription price discounted
   */
  @autoserializeAs(() => String, 'price_discounted') discountedPrice: string;

  /**
   *
   * @param price
   * @param currency
   * @param interval
   * @param intervalCount
   * @param discountedPrice
   */

  constructor(price: string, currency: string, interval: string, intervalCount: number, discountedPrice: string) {
    this.price = price;
    this.currency = currency;
    this.interval = interval;
    this.intervalCount = intervalCount;
    this.discountedPrice = discountedPrice;
  }
}

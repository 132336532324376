import { autoserializeAs } from 'dcerialize';

/**
 * Class that represents a document URL
 */
export class DocumentUrl {
  /**
   * Document URL
   */
  @autoserializeAs(() => String) documentUrl?: string;
}

/**
 * Class that contain a url to redirect
 */
export class UrlRedirect {
  /**
   * URL
   */
  @autoserializeAs(() => String) url?: string;

  constructor(url: string) {
    this.url = url;
  }
}
